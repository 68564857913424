import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PPEPageTemplate from '../components/PPEPageTemplate'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'


const PPEPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <PPEPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        meta_title={post.frontmatter.meta_title}
        meta_description={post.frontmatter.meta_description}
        contentComponent={HTMLContent}
        content={post.html}
        offerings={post.frontmatter.offerings}
      />
    </Layout>
  )
}

PPEPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PPEPage

export const PPEPageQuery = graphql`
  query PPEPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        heading
        offerings {
          blurbs {
            image
            text
            title
            linkTo
          }
        }
      }
    }
  }
`
