import React from 'react'
import Helmet from 'react-helmet'
import Content from '../Content'
import Offerings from '../Offerings'
import PropTypes from 'prop-types'
import './index.css'
import TypeformWidget from './TypeformWidget'
import '@brainhubeu/react-carousel/lib/style.css'

function figureOutType (name) {
  if (name.includes('Request')) {
    return 'RequestPPE'
  } else if (name.includes('Make')) {
    return 'MakePPE'
  } else if (name.includes('Give')) {
    return 'GivePPE'
  } else if (name.includes('Volunteer')) {
    return 'VolunteerPPE'
  }
}

const PPEPageTemplate = ({ title, subtitle, meta_title, meta_description, content, contentComponent, offerings }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section id={figureOutType(title)} className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='zoom-out' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='zoom-out' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {(title === 'Volunteer for PPE4ALL') && <section className='section3 cards'>
        <div className='container'>
          <div className='section'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='content' data-aos='zoom-in' data-aos-duration='500'>
                  <Offerings gridItems={offerings.blurbs} className='column is-4' sectionName='Volunteer' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
      {title !== 'Volunteer for PPE4ALL' && <section className='section3 aboutus'>
        <div className='columns'>
          <div className='column is-half ppepage'>
            <div className='column is-10 is-offset-1 is-sized'>
              <div className='mapTextGroup'>
                <h5 className='bodyText' data-aos='zoom-in' data-aos-duration='1000'>
                  <PageContent className='content' content={content} />
                </h5>
              </div>
            </div>
          </div>
          <div className='column is-half'>
            <div className='Examples__widget-section'>
              <TypeformWidget />
            </div>
          </div>
        </div>
      </section>}
      {(title === 'Make PPE') && <section className='section3 cards'>
        <div className='container'>
          <div className='section'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='content'>
                  <Offerings gridItems={offerings.blurbs} className='column is-4' sectionName='Volunteer' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
    </div>
  )
}

PPEPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default PPEPageTemplate
