import React, { Component } from 'react'
import * as typeformEmbed from '@typeform/embed'

class TypeformWidget extends React.Component {
  constructor(props) {
    super(props);
    this.el = null;
  }
  componentDidMount() {
    if (this.el) {
      typeformEmbed.makeWidget(this.el, "https://form.typeform.com/to/GJB5U35s", {
        hideFooter: true, 
        hideHeaders: true,
        opacity: 100
      });
    }
  }
  render() {
    return (
      <div ref={(el) => this.el = el} style={{width: '100%', height: '100%'}} />
    )
  }
}

export default TypeformWidget;